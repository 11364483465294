/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";
import { useAuth } from "gatsby-theme-firebase";
import Layout from "../components/layout"
import Button from "gatsby-theme-firebase/src/components/Button";
import LoginModal from "../components/LoginModal";
import Colors from "../components/colors"
import CompanyList from "../components/CompanyList"

const CompaniesPage = () => {
  const [toggleLogin, setToggleLogin] = useState(false)
  const { isLoading, isLoggedIn, profile } = useAuth()

  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  return(
    <Layout resetPadding>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        padding:'2rem 3rem',
        height: '100%',
        width: '100%',
        backgroundColor: Colors.gray[0]
      }}>
      {
        isLoggedIn && profile.emailVerified ? (
          <div style={{maxWidth: 1000, width: '100%'}}>
            <CompanyList />
          </div>
        )
        : (
          <div>
            <h1>You must be logged in to see this page.</h1>
            <Button
              onClick={() => {
                setToggleLogin(true);
              }}
            >
              Login
            </Button>
          </div>
        )
      }
      {toggleLogin && <LoginModal setToggleLogin={setToggleLogin} />}
      </div>
    </Layout>
  )
  
}

export default CompaniesPage
